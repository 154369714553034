<template>
    <tr :class="classes">
        <slot />
    </tr>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "table-row hover:bg-black hover:bg-opacity-90",
        };
    },
    computed: {
        classes() {
            return `table-row ${this.baseClasses}`;
        },
    },
};
</script>
